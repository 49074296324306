import React from "react";
import "./css/index.css"
import IndexBlocklyGames from './index.js';

const BlocklyGamesComponent = () => {
  

    return (
        <div>
            <IndexBlocklyGames/>
        </div>
    )
};

export default BlocklyGamesComponent;
