import React from "react";
import Directions from './AppRoutes';


function App(){
  return(
      <Directions></Directions>
  );
}

export default App;