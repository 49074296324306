import React from 'react';
import Header from '../../Components/Header';

export default function Home(){
    
    return(
        <div>
            <header>
                <Header></Header>
            </header>
            <h1>PAGINA PRINCIPAL</h1>
        </div>   
    );
}